import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3a5e4526"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "col-12"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "meta" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.blogPost.id)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "head"
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent('script'), { type: "application/ld+json" }, {
            default: _withCtx(() => [
              _createTextVNode(" [ { \"@context\":\"http://schema.org\", \"@type\":\"Website\", \"name\":\"MartijnKlene.nl\", \"url\":\"https://martijnklene.nl\" }, { \"@context\":\"https://schema.org\", \"@type\":\"BlogPosting\", \"headline\":\"" + _toDisplayString(_ctx.blogPost.title) + "\", \"datePublished\":\"" + _toDisplayString(_ctx.blogPost.publishedAt) + "\", \"keywords\":\"" + _toDisplayString(_ctx.blogPost.tags) + "\", \"url\":\"https://martijnklene.nl/detail/" + _toDisplayString(_ctx.blogPost.id) + "\", \"author\":\"" + _toDisplayString(_ctx.blogPost.author) + "\", \"publisher\": { \"@type\":\"Organization\", \"url\": \"https://martijnklene.nl\", \"name\":\"Martijn Klene\", \"logo\":\"https://pbs.twimg.com/profile_images/998841428238262274/g71Qp9j2_400x400.jpg\" }, \"image\":\"https://pbs.twimg.com/profile_images/998841428238262274/g71Qp9j2_400x400.jpg\" } ] ", 1)
            ]),
            _: 1
          }))
        ]))
      : _createCommentVNode("", true),
    (_ctx.blogPost.id)
      ? (_openBlock(), _createElementBlock("article", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("header", null, [
                _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.blogPost.title), 1),
                _createElementVNode("p", _hoisted_5, "Published at: " + _toDisplayString(_ctx.formatDate(_ctx.blogPost.publishedAt)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "col-12",
              innerHTML: _ctx.blogPost.content
            }, null, 8, _hoisted_7)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}