import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c1197d84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "list-group list-group-flush" }
const _hoisted_3 = { class: "meta" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogPosts, (blogPost) => {
        return (_openBlock(), _createElementBlock("article", {
          class: "list-group-item",
          key: blogPost.id
        }, [
          _createElementVNode("header", null, [
            _createElementVNode("h1", null, [
              _createVNode(_component_router_link, {
                to: { name: 'detail', params: { id: blogPost.id } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(blogPost.title), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _createElementVNode("p", _hoisted_3, "Published at: " + _toDisplayString(_ctx.formatDate(blogPost.publishedAt)), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}